import React from 'react';
import { Helmet } from 'react-helmet-async';

export const Imbox = ({ id }) => (
   <Helmet>
      <script type="text/javascript">{`
			var _sid = '${id}';
			(function() {
			var se = document.createElement('script'); se.type = 'text/javascript'; se.async = true;
			se.src = atob('aHR0cHM6Ly9maWxlcy5pbWJveC5pby9hcHAvZGlzdC9pbml0V2lkZ2V0Lmpz')
			var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(se, s);
			}());
			`}</script>
   </Helmet>
);
