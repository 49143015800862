module.exports = {
   default: {
      colors: {
         white: '#FFFFFF',
         black: 'var(--text)',
         main: 'var(--text)',
         grey: 'var(--gray-1)',
         darkerGrey: 'var(--gray-2)',
         lightgrey: '#E8E8E8',
         tablegrey: '#F3F3F3',
         beige: '#f5f5dc',
         loadingBar: 'var(--gray-2)',
         blue: 'var(--gray-2)',
         red: '#EB0000',
         background: 'var(--background)',
         darkgrey: '#6E6161',
         mediumgrey: '#9a9a9a',
         highlight: '#2f80ed',
      },
      fontWeights: {
         light: 300,
         regular: 400,
         semibold: 700,
      },
      breakpoints: {
         xs: '20rem',
         sm: '40rem', // 640px
         md: '50rem', // 800px
         lg: '64rem', // 1024px
         xl: '80rem', // 1280px
      },
      fontSizes: [
         '12px',
         '14px',
         '16px',
         '20px',
         '24px',
         '32px',
         '48px',
         '64px',
         '72px',
      ],
      space: [
         '0px',
         '8px',
         '16px',
         '24px',
         '32px',
         '40px',
         '48px',
         '56px',
         '64px',
      ],
      fonts: {
         primary: 'Ginger',
      },
   },
   alt: {
      colors: {
         blue: 'blue',
         main: 'black',
      },
   },
};
