import React from 'react';
import { Link } from 'react-router-dom';

import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import t from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { styled } from 'linaria/react';

import { theme } from '../../Theme';
import CloseButton from '../../ui/CloseButton';
import MobileCategories from './Categories/MobileCategories';
import ChannelSelectorModal from './ChannelSelector/ChannelSelectorModal';
import filterHiddenCategories from './Categories/__util__/filterHiddenCategories';

const InnerDrawer = styled('div')`
   padding: 0 1.25rem;
   position: relative;
`;

const StyledCloseButton = styled(CloseButton)`
   margin-left: auto;
   margin-right: -6px;
`;

const Title = styled('h1')`
   text-align: center;
   position: absolute;
   font-size: var(--text-size);
   font-family: var(--text-font);
   text-transform: uppercase;
   font-weight: bold;
`;

const NavBarHeader = styled('div')`
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   flex: 0 0 auto;
   height: 75px;
   border-bottom: 1px solid ${theme.colors.grey};
   margin-bottom: 0.75rem;
`;

const Scroll = styled('div')`
   overflow-y: auto;
   overflow-x: hidden;
   -webkit-overflow-scrolling: touch;
   flex: 1 1 auto;
`;

const SecondaryMenu = styled.ul`
   margin: 0;
   padding: 0;
   display: flex;
   flex: 1 0 auto;
   flex-direction: column;
   flex: 0 0 auto;
`;

const SecondaryMenuItem = styled.li`
   list-style: none;
   display: block;
   padding: 1em 1em 0.9375em;
   a,
   a:active {
      text-decoration: none;
      color: #666;
   }
   button {
      background: none;
      color: #666;
   }
`;

export default function MobileMenu({ data }) {
   const { loggedIn } = useAuth();
   const { routes } = useShopConfig();
   if (!(data && data.categories)) return null;

   const categories = data.categories?.filter(category =>
      filterHiddenCategories(category),
   );

   return (
      <DrawerTarget id="menu-drawer">
         {drawer => (
            <Drawer isOpen={drawer.isOpen}>
               <InnerDrawer>
                  <NavBarHeader>
                     <Title>{t('Menu')}</Title>
                     <StyledCloseButton onClick={drawer.hideTarget} />
                  </NavBarHeader>
                  <Scroll>
                     <MobileCategories
                        categories={categories}
                        closeMenu={drawer.hideTarget}
                     />
                     <SecondaryMenu>
                        <SecondaryMenuItem>
                           <Link
                              onClick={drawer.hideTarget}
                              to={routes.myPages.path}
                           >
                              {loggedIn ? t('My Pages') : t('Log in')}
                           </Link>
                        </SecondaryMenuItem>
                        {/*<SecondaryMenuItem>
                           <Link
                              to={routes.stores.path}
                              onClick={drawer.hideTarget}
                           >
                              {t('Find Store')}
                           </Link>
                        </SecondaryMenuItem>*/}
                        <SecondaryMenuItem>
                           <ChannelSelectorModal />
                        </SecondaryMenuItem>
                     </SecondaryMenu>
                  </Scroll>
               </InnerDrawer>
            </Drawer>
         )}
      </DrawerTarget>
   );
}
