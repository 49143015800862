import React from 'react';
import AnimateHeight from 'react-animate-height';

import CategoryLink from '@jetshop/ui/CategoryLink';
import { css } from 'linaria';
import { styled } from 'linaria/react';

import { theme } from '../../../Theme';
import { MaxWidthNoFlex } from '../../MaxWidth';

const Wrapper = styled('section')`
   display: flex;
   justify-content: center;
   z-index: 15;
   position: absolute;
   right: 0;
   left: 0;
   background: ${theme.colors.white};
   overflow: hidden;
   will-change: height;
   transition: height 0.3s ease-in-out;
`;

const Inner = styled(MaxWidthNoFlex)`
   // Padding to align submenu with logo
   // so calc(padding-left + grid gap + logo width)
   padding-left: calc(2rem + 70px + 170px);

   a {
      padding: 12px 0;
   }
`;

const SubMenuGrid = styled.div`
   display: grid;
   padding-bottom: 12px;
`;

const categoryLink = css`
   text-transform: uppercase;
   font-size: 14px;
`;

const Submenu = ({ pose, activeCategory, closeNav }) => {
   return (
      <Wrapper>
         <Inner>
            <AnimateHeight height={pose === 'open' ? 'auto' : 0} duration={350}>
               {activeCategory && (
                  <SubMenuGrid>
                     {activeCategory.subcategories?.map(cat => (
                        <CategoryLink
                           key={cat.id}
                           category={cat}
                           onClick={closeNav}
                           className={categoryLink}
                        >
                           {cat.name}
                        </CategoryLink>
                     ))}
                  </SubMenuGrid>
               )}
               <div>{/* Animate height can't be empty*/} </div>
            </AnimateHeight>
         </Inner>
      </Wrapper>
   );
};

export default Submenu;
