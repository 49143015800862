import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import MaxWidth from '../MaxWidth';

//Import of svg icons
import { ReactComponent as Icon1 } from '../../../svg/europa.svg';
import { ReactComponent as Icon2 } from '../../../svg/swedishdesign.svg';
import { ReactComponent as Icon3 } from '../../../svg/personalservice.svg';
import { ReactComponent as Icon4 } from '../../../svg/garment.svg';

const Wrapper = styled('div')`
   background-color: var(--background);
   text-align: center;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding-left: 0.75rem;
   padding-right: 0.75rem;
   padding-top: 60px;
   padding-bottom: 60px;
   margin-top: -1px;
   ${theme.above.sm} {
      padding-left: 2rem;
      padding-right: 2rem;
   }

   h2 {
      line-height: normal;
   }
`;

const UspsWrapper = styled('div')`
   max-width: 900px;
   width: 100%;
   display: grid;
   grid-template-columns: repeat(4, 180px);
   justify-content: space-between;
   margin-top: var(--spacing-m);

   ${theme.below.md} {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 40px;
      grid-row-gap: 30px;
   }

   @media (max-width: ${theme.breakpoints.xs}) {
      grid-column-gap: 10px;
   }
`;

const Usp = styled('div')`
   display: flex;
   align-items: center;
   flex-direction: column;

   span {
      text-transform: uppercase;
      margin-top: var(--spacing-s);
      font-size: 14px;
      line-height: 20px;
   }
`;

const IconUsps = () => (
   <Wrapper>
      <h2>We care</h2>
      <UspsWrapper>
         <Usp>
            <Icon1 />
            <span>Manufactured in Europe</span>
         </Usp>
         <Usp>
            <Icon2 />
            <span>Design by women for women</span>
         </Usp>
         <Usp>
            <Icon3 />
            <span>Personal customerservice</span>
         </Usp>
         <Usp>
            <Icon4 />
            <span>Highquality fabrics</span>
         </Usp>
      </UspsWrapper>
   </Wrapper>
);

export default IconUsps;
