import {
   useProductList,
   useProductListItems,
} from '@jetshop/core/hooks/ProductList';
import { useAddMultipleToCart } from '@jetshop/core/hooks/useAddMultipleToCart';
import t from '@jetshop/intl';
import React from 'react';
import { css, cx } from 'linaria';
import { ReactComponent as Cart } from '../../svg/Cart.svg';
import addMultipleToCartMutation from '../Cart/addMultipleToCart.gql';
import cartQuery from '../Cart/CartQuery.gql';
import MaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theme';
import Button from '../ui/Button';
import { Product } from './Product';
import { useNotification } from '@jetshop/core/components/Notifications';

export function Favourites() {
   const { products, loading } = useProductListItems();
   const [trigger] = useNotification();

   const validItems = getItemsForAddToCart(products);
   const validItemCount = validItems.length;

   const [addToCart, { loading: mutationLoading }] = useAddMultipleToCart(
      validItems,
      {
         addMultipleToCartMutation,
         cartQuery,
      },
      {
         onCompleted: () => {
            trigger(
               <AddAllSuccessToast>
                  <Cart />
                  {t(
                     '{productCount, plural, =1 {# product added to the cart.} other {# products added to the cart.}}',
                     { productCount: validItemCount },
                  )}
               </AddAllSuccessToast>,
            );
         },
      },
   );

   const noProductsInList = products.length === 0 && !loading;
   const loadingInitialRender = products.length === 0 && loading;

   if (noProductsInList) {
      return (
         <MaxWidth className={cx(container, loading ? 'loading' : null)}>
            <h1>{t('Saved items')}</h1>
            <p>{t('There are no products in your saved items list.')}</p>
         </MaxWidth>
      );
   }

   if (loadingInitialRender) {
      return (
         <MaxWidth className={cx(container, loading ? 'loading' : null)}>
            <h1>{t('Saved items')}</h1>
            <p>{t('Loading your saved items…')}</p>
         </MaxWidth>
      );
   }

   return (
      <MaxWidth className={cx(container, loading ? 'loading' : null)}>
         <h1>{t('Saved items')}</h1>
         <ul className="product-grid">
            {products.map((product, index) => {
               return (
                  <li
                     key={
                        product.variant?.articleNumber || product.articleNumber
                     }
                     data-valid={product.validation.status}
                  >
                     <Product product={product} />
                  </li>
               );
            })}
         </ul>

         <div className="add-clear">
            <ClearList>{t('Clear list')}</ClearList>
         </div>
      </MaxWidth>
   );
}

function AddAllSuccessToast({ children }) {
   return <div className={addAllToastStyles}>{children}</div>;
}

function ClearList({ children }) {
   const { clear } = useProductList();
   return (
      <Button secondary onClick={clear}>
         {children}
      </Button>
   );
}

function getItemsForAddToCart(products) {
   // When adding to cart we only want valid items, and each articleNumber needs
   // to be the variant rather than the base product
   return products
      .filter(product => {
         return product.validation.status === 'valid';
      })
      .map(product => ({
         ...product,
         articleNumber: product.isVariant
            ? product.variant.articleNumber
            : product.articleNumber,
      }));
}

const container = css`
   margin-top: 2em;
   &.loading {
      opacity: 0.6;
   }

   h1 {
      margin-bottom: 1em;
      font-weight: 600;
   }

   .product-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;
      margin-right: -${theme.space[1]};
      margin-left: -${theme.space[1]};
      width: 100%;

      > * {
         line-height: 19px;
         min-width: 0;
         margin: ${theme.space[1]};
         width: calc(100% - ${theme.space[2]});

         ${theme.above.md} {
            width: calc(50% - ${theme.space[2]});
         }

         ${theme.above.lg} {
            width: calc(33.333% - ${theme.space[2]});
         }
         ${theme.above.xl} {
            width: calc(25% - ${theme.space[2]});
         }
      }
   }

   .product-card {
      a {
         margin: 0;
         border: 0;
      }
   }

   li:not([data-valid='valid']) .product-card {
      a {
         background: rgba(255, 255, 255, 0.2);
      }
   }

   .select-variant {
      margin-top: 1.25rem;
      position: relative;
   }

   .add-clear {
      display: flex;
      justify-content: center;
      margin: 0.5;
      gap: 1.25rem;
   }

   .add-clear button {
      display: block;
   }

   .new-price,
   .old-price {
      display: inline-block;
   }
   .old-price {
      margin-left: 0.5em;
   }
`;

const addAllToastStyles = css`
   background: white;
   color: ${theme.colors.black};
   padding: 1em;
   display: flex;
   align-items: center;

   svg {
      max-width: 1.5em;
      max-height: 1.5em;
      margin-right: 0.5em;
   }
`;
