import React from 'react';
import AnimateHeight from 'react-animate-height';

import { SearchField } from '@jetshop/ui/Search';
import { css } from 'linaria';
import { styled } from 'linaria/react';

import { MaxWidthNoFlex } from '../MaxWidth';

import AutocompleteQuery from './AutocompleteQuery.gql';

const Container = styled.div`
   position: absolute;
   width: 100%;
   background: var(--vit);
   padding-bottom: 12px;
   z-index: 5;
`;

const InnerSearchBar = styled(MaxWidthNoFlex)`
   position: relative;
   max-width: 800px;
`;

const animateHeightContainer = css`
   position: absolute;
   z-index: 15;
   width: 100%;
   overflow: hidden;
   top: 100%;
`;

const searchFieldStyles = css`
   --base-unit: 12px;

   &[data-flight-searchfield] > * {
      // This is first container <div>
      top: 0;
      padding: 0 var(--base-unit);
      display: flex;
      width: 100% !important;
      justify-content: center;
      align-items: center;

      input {
         font-size: 16px;
         line-height: 16px;
         height: 100%;
         width: 100%;
         padding: var(--base-unit) 8px;
         border: 1px solid var(--gray-1);

         &:focus {
            outline: none;
         }

         #search-clear {
            display: none;
         }

         &::-webkit-search-decoration,
         &::-webkit-search-cancel-button,
         &::-webkit-search-results-button,
         &::-webkit-search-results-decoration {
            -webkit-appearance: none;
         }
      }
   }

   [data-flight-searchfield-cancel] {
      height: 100%;
      min-width: 48px;
      width: fit-content;
   }

   [data-flight-searchfield-flyout] {
      text-align: left;
      top: calc(100% + 12px);
      border: 0;

      > * {
         // Autocomplete results wrapper <div>
         padding: var(--base-unit);
      }

      h2 {
         font-family: var(--text-font), sans-serif;
         font-size: 16px;
         padding-bottom: 6px;
         border-bottom: 1px solid var(--gray-1);
         margin-bottom: 6px;
      }

      li {
      }

      a {
         padding: 6px 0;
         color: black;
         line-height: 21px;
         display: block;
      }
   }
`;

const SearchBar = ({ setSearchIsOpen, searchIsOpen, focusSearch }) => (
   <AnimateHeight
      height={searchIsOpen ? 352 : 0}
      duration={350}
      className={animateHeightContainer}
      onAnimationEnd={() => focusSearch()}
   >
      <Container key={'searchBarPosed'}>
         <InnerSearchBar>
            <SearchField
               autocompleteQuery={AutocompleteQuery}
               onCancel={() => setSearchIsOpen(false)}
               onSubmit={() => setSearchIsOpen(false)}
               className={searchFieldStyles}
            />
         </InnerSearchBar>
      </Container>
   </AnimateHeight>
);

export { SearchBar };
