import * as ProductListQueries from './ProductLists.gql';

export const productListQueries = {
   query: ProductListQueries.ProductList,
   productsQuery: ProductListQueries.ProductsFromProductList,
   add: ProductListQueries.AddToProductList,
   login: ProductListQueries.MergeProductLists,
   update: ProductListQueries.UpdateProductListItem,
   remove: ProductListQueries.RemoveFromProductList,
};
