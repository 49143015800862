import t from '@jetshop/intl';
import React from 'react';
import { css } from 'linaria';
import { ProductCard } from '../CategoryPage/ProductCard';
import { AddToCart } from './AddToCart';
import { SelectVariant } from './SelectVariant';
import { ReactComponent as TrashSVG } from '../../svg/trash.svg';
import { useProductList } from '@jetshop/core/hooks/ProductList';
import { baseStyles as buttonBaseStyles } from '../ui/Button';

export function Product({ product, loading, children }) {
   // Use the variant's image for display
   const productForDisplay = { ...product };
   if (product.isVariant && product.variant.images) {
      productForDisplay.images = product.variant.images;
   }

   return (
      <>
         <ProductCard product={productForDisplay} as="div">
            <RemoveFromList
               articleNumber={product.articleNumber}
               variant={product.variant}
            />
         </ProductCard>
      </>
   );
}

function RemoveFromList({ children, articleNumber, variant }) {
   const { remove } = useProductList();
   const variantArticleNumber = variant?.articleNumber;
   return (
      <button
         className={removeStyles}
         onClick={() => remove(articleNumber, { variantArticleNumber })}
      >
         <TrashSVG />
      </button>
   );
}

function InvalidProduct({ validation }) {
   const { status } = validation;
   const reasonMessages = {
      outOfStock: t('Out of stock'),
      missingVariant: t('Select options'),
      preOrder: t('Visit product page to add'),
      configurations: t('Visit product page to add'),
      package: t('Visit product page to add'),
   };
   return (
      <div disabled className={invalidProductStyles}>
         {reasonMessages[status] || t('Not available')}
      </div>
   );
}

const removeStyles = css`
   position: absolute;
   top: 0.5em;
   right: 0.5em;
   background: white;
   border: 1px solid silver;
   border-radius: 50%;
   padding: 0.5em;
   svg {
      height: 1.25em;
      width: 1.25em;
   }
`;

const invalidProductStyles =
   css`
      ${buttonBaseStyles}
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: calc(1.25em + 2px);
   ` + ' secondary';
