import React from 'react';

import { useNewsletterSubscription } from '@jetshop/core/hooks/Subscriptions/useNewsletterSubscription';
import { useIntl } from '@jetshop/intl';
import { css } from 'linaria';
import { styled } from 'linaria/react';

import GlobalContent from '../ContentElements/GlobalContent';
import { theme } from '../Theme';

const Wrapper = styled('div')`
   width: 100%;
   display: grid;
   grid-row-gap: 30px;

   ${theme.above.md} {
      grid-column-gap: 54px;
      grid-template-columns: 1fr 2fr;
      align-items: end;
   }
`;

const NewsletterText = css`
   ${theme.below.md} {
      margin-bottom: 30px;
   }
`;

const FormWrapper = styled('div')`
   width: 100%;

   form {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      font-family: var(--text-font);

      div {
         width: 100%;
         margin-right: 22px;
         ${theme.below.md} {
            margin: 0;
         }
      }

      ${theme.below.md} {
         flex-direction: column;
         align-items: flex-start;
      }
   }

   input {
      font-family: var(--text-font);
      background: transparent;
      display: flex;
      border: none;
      border-bottom: 1px solid var(--text-color);
      font-size: var(--text-size);
      height: 43px;
      width: 100%;
      &:focus {
         outline: none;
      }
   }
   label {
      font-size: 14px;
   }
   button {
      height: 43px;
      background: var(--vit);
      border: 0;
      outline: none;
      top: 0;
      min-width: 194px;
      cursor: pointer;
      text-transform: uppercase;
      ${theme.below.md} {
         margin-top: 20px;
      }
   }
`;

const Error = styled('div')`
   color: var(--red);
   margin-bottom: 16px;
`;

function NewsletterField() {
   const {
      inputProps,
      submit,
      submitting,
      submitted,
      failed,
      errorStates: { alreadySubscribed, invalidEmail },
   } = useNewsletterSubscription({
      enabled: true,
   });

   const t = useIntl();
   const disabled = inputProps.value.length === 0 || submitting;
   const hasError = alreadySubscribed || invalidEmail || failed;

   return (
      <Wrapper>
         <label htmlFor="newsletter">
            <GlobalContent
               className={NewsletterText}
               contentId="newsletterText"
            />
         </label>

         <FormWrapper>
            {hasError && (
               <Error>
                  {alreadySubscribed &&
                     t('You have already subscribed to the newsletter!')}
                  {invalidEmail || failed
                     ? t(
                          'Something went wrong. Please check your email and try again.',
                       )
                     : null}
               </Error>
            )}

            {submitted ? (
               <p data-testid="newsletter-subscription-success">
                  {t('You are now subscribed')}
               </p>
            ) : (
               <form onSubmit={submit} disabled={disabled}>
                  <div>
                     <label>E-POST</label>
                     <input
                        type="email"
                        id="newsletter"
                        style={{ paddingRight: submitting ? '8rem' : '4rem' }}
                        data-testid="newsletter-subscription-input"
                        {...inputProps}
                     />
                  </div>
                  <button
                     type="submit"
                     disabled={disabled}
                     data-testid="newsletter-subscription-submit"
                  >
                     {submitting ? t('Submitting…') : t('Submit')}
                  </button>
               </form>
            )}
         </FormWrapper>
      </Wrapper>
   );
}

export default NewsletterField;
