import React, { useEffect, useRef } from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext';

import { styled } from 'linaria/react';

const SnappptWrapper = styled('div')``;

export const Snapppt = () => {
   const snappptRef = useRef();
   const { selectedChannel } = React.useContext(ChannelContext);

   const countryCode = selectedChannel?.country?.code;

   useEffect(() => {
      const script = document.createElement('script');

      script.src =
         countryCode === 'FI' || countryCode === 'AX'
            ? 'https://snapppt.com/widgets/widget_loader/3df28b80-c0f7-9231-d6a7-2fb8abb0ff5c/sauce_homepage.js'
            : 'https://snapppt.com/widgets/widget_loader/98f2c1b9-3980-46f8-b13f-92e6074932b4/sauce_insta_shop.js';
      script.async = true;
      script.classList.add('snapppt-widget');

      snappptRef.current.appendChild(script);

      return () => {
         snappptRef.current.removeChild(script);
      };
   }, [snappptRef]);

   return <SnappptWrapper ref={snappptRef} />;
};

export default Snapppt;
