import React from 'react';
import { styled } from 'linaria/react';

import CategoryLink from '@jetshop/ui/CategoryLink';
import UIMenuContainer from '@jetshop/ui/Menu/MenuContainer';

import Submenu from './SubMenu';
import filterHiddenCategories from '../Categories/__util__/filterHiddenCategories';

const Wrapper = styled('div')`
   ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
   }
`;

const MainMenuItem = styled('li')`
   a {
      font-size: 14px;
      text-transform: uppercase;
      padding: 1rem;
      display: inline-block;
      text-decoration: none;
   }
`;

const CategoryMenuContainer = ({ queryData }) => {
   return (
      <UIMenuContainer>
         {props => {
            return (
               // When mousing outside the menu, close it
               <Wrapper onMouseLeave={props.clearActiveCategories}>
                  <ul>
                     {queryData?.categories
                        ? queryData.categories
                             .filter(category =>
                                filterHiddenCategories(category),
                             )
                             .map(cat => (
                                <CategoryMenuItem
                                   key={cat.id}
                                   cat={cat}
                                   props={props}
                                />
                             ))
                        : null}
                  </ul>

                  <Submenu
                     pose={
                        props.activeCategories.length > 0 &&
                        props.activeCategories[0].subcategories.length > 0
                           ? 'open'
                           : 'closed'
                     }
                     activeCategory={props.activeCategories[0]}
                     closeNav={props.clearActiveCategories}
                  />
               </Wrapper>
            );
         }}
      </UIMenuContainer>
   );
};

const CategoryMenuItem = ({ cat, props }) => {
   return (
      <MainMenuItem
         // When mousing over a menu item, set it as the active nav
         onMouseEnter={() => props.setActiveCategory(cat)}
      >
         <CategoryLink
            // When following a category link, close the menu
            onClick={props.clearActiveCategories}
            category={cat}
         >
            {cat.name}
         </CategoryLink>
      </MainMenuItem>
   );
};

export default CategoryMenuContainer;
