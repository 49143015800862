import React from 'react';
import { styled } from 'linaria/react';
import { ReactComponent as CaretIcon } from '../../svg/Caret.svg';

const Up = styled.span`
   transform: rotate(180deg);
`;

const Down = styled.span`
   transform: rotate(0deg);
`;

const Components = {
   up: Up,
   down: Down,
};

export function Caret({ open }) {
   if (typeof open === 'undefined') return null;

   const Component = Components[open ? 'up' : 'down'];
   return (
      <Component>
         <CaretIcon />
      </Component>
   );
}
