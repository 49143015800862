import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const Wrapper = styled('div')`
   display: flex;
   align-items: center;
   justify-content: center;
   height: 16px;
   width: 16px;
   border: 0;
   border-radius: 50%;
   line-height: 16px;
   background-color: var(--gray-1);
   overflow: hidden;

   span {
      height: 16px;
      width: 16px;
      border: 0;
      border-radius: 50%;
      font-size: 0.5rem;
      line-height: 16px;
      color: ${theme.colors.white};
      background-color: ${theme.colors.blue};
      overflow: hidden;
   }
`;

const Badge = ({ text }) => {
   return <Wrapper className="badge">{text}</Wrapper>;
};

export default Badge;
