import { useQuery } from '@apollo/react-hooks';
import camelcase from 'camelcase';

import { GLOBAL_CONTENT } from '../components/ContentElements/elementTypes';

import globalContentQuery from '../queries/globalContentQuery.gql';

/**
 * @typedef {object} useGlobalContentReturn
 * @property {boolean} loading
 * @property {(contentId:string) => string} getGlobalContent Get content HTML by content id
 * @property {object} globalContent All global content as object
 */

/**
 * Site settings hook
 *
 * @example
 * // Usage
 *    const { globalContent, getGlobalContent, loading } = useGlobalContent()
 *    if (loading) return 'Loading...';
 * @returns {useGlobalContentReturn} Loading state and getters
 */

export const useGlobalContent = () => {
   const { data, loading } = useQuery(globalContentQuery);

   /**
    * @param {import('@apollo/react-common').QueryResult} data
    */
   function filterData(data) {
      const contentItems = data?.startPage?.data?.items.filter(
         item => item.type === GLOBAL_CONTENT,
      );

      let output = [];
      contentItems?.forEach(item => {
         const key = item.properties.find(i => i.name === 'contentId')?.value
            ?.value;
         const value = item.properties.find(i => i.name === 'html')?.value
            ?.value;

         if (!key || !value) {
            return;
         }

         output[camelcase(key)] = value;
      });

      return output;
   }

   const globalContent = filterData(data);
   const getGlobalContent = contentId => globalContent?.[contentId] ?? '';

   return { globalContent, getGlobalContent, loading };
};

export default useGlobalContent;
