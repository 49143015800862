import React from 'react';
import { css } from 'linaria';

import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import SvgLogo from '../../../svg/Logo.svg';
import { theme } from '../../Theme';

const Wrapper = styled.div`
   @media (max-width: ${theme.breakpoints.xl}) {
      width: 119px;
      img {
         max-width: 100%;
      }
   }
`;

const linkStyle = css`
   margin: auto;
`;

const Logo = () => (
   <Link to="/" className={linkStyle}>
      <Wrapper>
         <img alt="GetRefilled" src={SvgLogo} />
      </Wrapper>
   </Link>
);

export { Logo };
