import Accordion from '@jetshop/ui/Accordion/Accordion';
import Link from '@jetshop/ui/CategoryLink';
import React, { Component } from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { Caret } from '../../../ui/Caret';

const CategoryTree = styled('ul')`
   display: flex;
   flex-direction: column;
   list-style: none;

   flex: 1 0 auto;

   a,
   a:hover {
      text-decoration: none;
   }
`;
const SubCategories = styled(CategoryTree)`
   padding-left: 1rem;
`;

const CategoryLink = styled(Link)`
   font-size: 22px;
   line-height: 68px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   color: inherit;
   text-decoration: none !important;
   padding: 0;
   padding-right: 10px;
   position: relative;

   ul li &.active,
   ul li ul li &.active {
      font-weight: bold;
   }
`;

const SubCategoryLink = styled(Link)`
   font-size: 18px;
   line-height: 51px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   color: inherit;
   text-decoration: none !important;
   padding: 0 0;
   position: relative;

   &:last-child {
      margin-bottom: 0.75rem;
   }

   ul li &.active,
   ul li ul li &.active {
      font-weight: bold;
   }
`;

const CategoryName = styled('h2')`
   font-family: var(--text-font);
   font-size: 22px;
   font-weight: 700;
`;

const SubCategory = styled('li')`
   font-size: 0.875rem;
`;

const RootCategory = styled('li')`
   display: flex;
   flex-direction: column;
`;

export default class DrawerMenu extends Component {
   renderSubCategories = category => {
      const { subcategories } = category;
      if (!subcategories || !subcategories.length) return null;
      const { closeMenu } = this.props;
      return (
         <SubCategories>
            {/* <SubCategory>
					<CategoryLink
						exact
						category={category}
						onClick={() => closeMenu()}
					>
						{t('View all in {category}', { category: category.name })}
					</CategoryLink>
				</SubCategory> */}
            {subcategories.map(cat => {
               return (
                  <SubCategory key={cat.id}>
                     <SubCategoryLink
                        category={cat}
                        onClick={() => closeMenu()}
                     />
                  </SubCategory>
               );
            })}
         </SubCategories>
      );
   };

   handleClick = (e, hasChildren, index, handleAccordionClick) => {
      // If there are subcategories, prevent following the link
      const { closeMenu } = this.props;
      if (hasChildren) {
         e.stopPropagation();
         e.preventDefault();
      } else {
         closeMenu();
      }
      handleAccordionClick(index);
   };

   render() {
      const { categories } = this.props;

      if (categories && categories.length > 0) {
         return (
            <CategoryTree className="root">
               <Accordion single>
                  {({ openIndexes, handleClick, AccordionContent }) =>
                     categories.map((cat, index) => {
                        let classNames = [];
                        // Give categories with children a chevron
                        let hasSubcategories = cat?.subcategories?.length > 0;
                        // Add 'open' class when accordion is opened
                        const open = openIndexes.includes(index);
                        open && classNames.push('open');

                        return (
                           <RootCategory key={cat.id}>
                              <CategoryName>
                                 <CategoryLink
                                    category={cat}
                                    onClick={e =>
                                       this.handleClick(
                                          e,
                                          cat.subcategories.length,
                                          index,
                                          handleClick,
                                       )
                                    }
                                    className={
                                       classNames.length > 0
                                          ? classNames.join(' ')
                                          : null
                                    }
                                 >
                                    {cat.name}
                                    {hasSubcategories && <Caret open={open} />}
                                 </CategoryLink>
                              </CategoryName>
                              <AccordionContent isOpen={open}>
                                 {this.renderSubCategories(cat)}
                              </AccordionContent>
                           </RootCategory>
                        );
                     })
                  }
               </Accordion>
            </CategoryTree>
         );
      } else return null;
   }
}
