import React from 'react';
import { styled } from 'linaria/react';
import { ReactComponent as FacebookLogo } from '../../../svg/facebook.svg';
import { ReactComponent as InstagramLogo } from '../../../svg/instagram.svg';
import { theme } from '../../Theme';

import ChannelContext from '@jetshop/core/components/ChannelContext';

const Wrapper = styled('div')`
   margin-right: 0;
   ul {
      display: flex;
      align-items: center;
      margin-top: 1rem;
      flex-direction: row;
      justify-content: flex-start;
   }

   li {
      margin: 0 1.5rem 0 0;
   }

   a.social-links {
      display: flex;
      justify-content: flex-end;
      align-items: center;
   }

   color: ${theme.colors.black};
`;

const LogoWrapper = styled('span')`
  //background: ${theme.colors.black};
  width: 2.5rem;
  height: 2.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  svg {
    max-width: 100%;
    max-height: 100%;
  }
`;

const SocialLinks = ({ showLabel = true }) => {
   const { selectedChannel } = React.useContext(ChannelContext);

   const countryCode = selectedChannel?.country?.code;

   const isFI = countryCode === 'FI' || countryCode === 'AX';

   const facebookLink = isFI
      ? 'https://www.facebook.com/houseoflolainternational/'
      : 'https://www.facebook.com/Houseoflolasweden/';
   const instagramLink = isFI
      ? 'https://www.instagram.com/houseoflolafi/'
      : 'https://www.instagram.com/houseoflola/';

   return (
      <Wrapper>
         <ul>
            <li>
               <a
                  className="social-links"
                  aria-label="Facebook"
                  href={facebookLink}
                  target="_blank"
                  rel="noopener noreferrer"
               >
                  <LogoWrapper>
                     <FacebookLogo />
                  </LogoWrapper>
                  {showLabel && <label>Facebook</label>}
               </a>
            </li>
            <li>
               <a
                  className="social-links"
                  aria-label="Instagram"
                  href={instagramLink}
                  target="_blank"
                  rel="noopener noreferrer"
               >
                  <LogoWrapper>
                     <InstagramLogo />
                  </LogoWrapper>
                  {showLabel && <label>Instagram</label>}
               </a>
            </li>
         </ul>
      </Wrapper>
   );
};

export default SocialLinks;
