import React from 'react';

import t from '@jetshop/intl';
import Image, { transparentDataImg } from '@jetshop/ui/Image/Image';
import { Price } from '@jetshop/ui/Price';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import { css, cx } from 'linaria';

import { theme } from '../Theme';

const wrapperStyling = css`
   position: relative;
   margin: 0 10px;
   margin-bottom: 50px;

   a {
      text-decoration: none;
      color: inherit;
      display: block;
   }

   .details {
      padding-top: var(--h3);

      h3 {
         line-height: 1.5;
         text-overflow: ellipsis;
         white-space: nowrap;
         overflow: hidden;
      }

      h4 {
         text-overflow: ellipsis;
         white-space: nowrap;
         overflow: hidden;
      }
   }

   .package-badge {
      background: black;
      color: white;
      font-size: 14px;
      padding: 3px 6px;
   }

   .price-package-wrapper {
      display: flex;
      align-items: center;
      .package-price-label {
         font-size: var(--text-size-small);
         color: grey;
         margin-left: 5px;
      }
   }

   .badge-top-right {
      top: 3em;
   }

   div[class^='badge-'] {
      left: 20px;
      top: 20px;
      ${theme.below.md} {
         left: 10px;
         top: 10px;
      }

      > * {
         background-color: var(--text-color);
         width: fit-content;
         margin-top: 10px;
         > span {
            font-weight: 700;
            padding: 7px 20px 6px 20px;
            text-transform: uppercase;
            color: var(--vit);
            font-size: var(--knapptext);
            letter-spacing: 0.1em;
            line-height: normal;
            ${theme.below.md} {
               padding: 5px 10px 5px 10px;
               font-size: 10px;
            }
         }
         &:first-child {
            margin-top: 0px;
         }
      }

      > .red {
         background-color: var(--red);
      }
      > .black {
         background-color: var(--text-color);
      }
   }

   [data-flight-image-container] {
      box-shadow: 4px 4px 29px rgba(0, 0, 0, 0.1);
   }
`;

export function ProductCard({
   product,
   className,
   imageAspect = '476:540',
   imageSizes = [1 / 4, 1 / 3, 1 / 2, 1 / 2],
   forwardRef,
   as = 'li',
   children,
   ...linkProps
}) {
   const hasImages = product.images && product.images.length > 0;
   const Tag = as;
   let badges = [...product.badges];

   product.isPackage &&
      badges.push({
         location: 'TOP_RIGHT',
         name: 'Package',
         text: t('Package'),
         style: 'package-badge',
      });

   return (
      <Tag
         className={cx('product-card', wrapperStyling, className)}
         data-testid="product"
         data-product-card
         ref={forwardRef}
      >
         <ProductLink product={product} {...linkProps}>
            {hasImages ? (
               <Image
                  sizes={imageSizes}
                  aspect={imageAspect}
                  alt={product.images[0].alt}
                  src={product.images[0].url}
                  modifiedDate={product.images[0].modifiedDate}
                  cover={true}
               >
                  <Badges badges={badges} />
               </Image>
            ) : (
               <Image src={transparentDataImg} />
            )}
            <section className="details">
               <header>
                  <h3>{product.name}</h3>
               </header>
               <div className="price-package-wrapper">
                  <Price
                     price={product.price}
                     previousPrice={product.previousPrice}
                  />
               </div>
            </section>
         </ProductLink>
         {children}
      </Tag>
   );
}
