import React from 'react';
import { Link } from 'react-router-dom';

import { useProductList } from '@jetshop/core/hooks/ProductList';
import t from '@jetshop/intl';

import { IconLabel } from '../Layout/Header/Header';
import Badge from '../ui/Badge';

import { ReactComponent as HeartSVG } from '../../svg/HeartAlt.svg';

export function FavouriteCount({ className }) {
   const { count } = useProductList();

   return (
      <Link to="/favourites">
         <div className={className}>
            {count > 0 && <Badge text={count} />}
            <HeartSVG />
         </div>
         <IconLabel>{t('Favourites')}</IconLabel>
      </Link>
   );
}
