import React from 'react';

import useGlobalContent from '../../hooks/useGlobalContent';

export const GlobalContent = ({ contentId, as, ...props }) => {
   const { getGlobalContent } = useGlobalContent();
   if (!contentId) {
      return null;
   }

   const Component = as || 'div';

   return (
      <Component
         dangerouslySetInnerHTML={{ __html: getGlobalContent(contentId) }}
         {...props}
      />
   );
};

export default GlobalContent;
