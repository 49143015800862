import React from 'react';

import { styled } from 'linaria/react';

import { GlobalContent } from '../../ContentElements/GlobalContent';
import NewsletterField from '../../Newsletter/NewsletterField';
import { theme } from '../../Theme';
import MaxWidth from '../MaxWidth';
import IconUsps from './IconUsps';

import SocialLinks from './SocialLinks';
import Snapppt from './Snapppt';

import ChannelContext from '@jetshop/core/components/ChannelContext';

import t from '@jetshop/intl';

const backgroundColor = 'var(--gray-3)';

const Wrapper = styled('section')`
   text-align: left;
   background: ${backgroundColor};

   h3 {
      margin-bottom: 12px;
   }
`;

const WrapFooter = styled(MaxWidth)`
   color: var(--vit);
   background: ${backgroundColor};
   display: flex;
   justify-content: space-between;
   flex-direction: row;
   padding-top: 88px;
   padding-bottom: 72px;

   address {
      font-style: normal;
   }

   ul {
      margin: 0;
      padding: 0;
      list-style: none;
   }
   a,
   p {
      padding: 8px 0;
   }
   a {
      display: block;
      color: var(--vit);
      text-decoration: none;
      :hover {
         color: ${theme.colors.blue};
         transition: all 0.3s linear;
      }
   }

   ${theme.below.md} {
      background: ${backgroundColor};
      padding-top: 48px;
      padding-bottom: 48px;
   }

   > section {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;
      > section {
         margin: 0 48px 0 0;
      }
   }

   ${theme.below.lg} {
      flex-direction: column;

      > section {
         justify-content: flex-start;
         margin-top: 48px;
         section {
         }
      }
   }
   ${theme.below.sm} {
      > section {
         flex-direction: column;
         justify-content: flex-start;
         section  {
            margin: 32px 0 0 0;
         }
      }
   }
`;
const AboutWrapper = styled('div')`
   margin: 0 48px 0 0;
   width: 100%;
   ${theme.below.lg} {
      margin: 0;
   }
`;

const NewsletterWrapper = styled('div')`
   background-color: var(--gray-1);
   padding-top: 88px;
   padding-bottom: 72px;

   ${theme.below.md} {
      padding-top: 48px;
      padding-bottom: 48px;
   }
`;

const InstragramWrapper = styled('div')`
   background: var(--bakgrund);
   padding-top: 80px;
   text-align: center;

   h3 {
      margin-bottom: 30px;
   }

   p {
      max-width: 400px;
      font-size: var(--text-size);
      margin: 0 auto;
   }
`;

const Footer = () => {
   const { selectedChannel } = React.useContext(ChannelContext);

   const countryCode = selectedChannel?.country?.code;

   const Hashtag = ({ children }) => <strong key="hashtag">{children}</strong>;
   const InstaLink = ({ children }) => (
      <a
         target="_blank"
         rel="noopener noreferrer"
         href={
            countryCode === 'FI' || countryCode === 'AX'
               ? 'https://www.instagram.com/houseoflolafi/'
               : 'https://www.instagram.com/houseoflola/'
         }
         key="instaLink"
      >
         {children}
      </a>
   );

   return (
      <Wrapper>
         <InstragramWrapper>
            <MaxWidth>
               <h3>{t('#houseoflola')}</h3>
               <Snapppt />
               <p>
                  {t.rich(
                     'Hashtagga dina bilder med <Hashtag>#houseoflola</Hashtag> och <InstaLink>följ oss på instagram</InstaLink> för att hålla dig uppdaterad.',
                     {
                        Hashtag,
                        InstaLink,
                     },
                  )}
               </p>
            </MaxWidth>
         </InstragramWrapper>
         <IconUsps />
         <NewsletterWrapper>
            <MaxWidth>
               <div className="newsletter-container">
                  <NewsletterField />
               </div>
            </MaxWidth>
         </NewsletterWrapper>
         <WrapFooter>
            <AboutWrapper>
               <GlobalContent as="section" contentId="footerLeftColumn" />
               <SocialLinks showLabel={false} />
            </AboutWrapper>
            <section>
               <GlobalContent as="section" contentId="footerMiddleColumn" />
               <GlobalContent as="section" contentId="footerRightColumn" />
            </section>
         </WrapFooter>
      </Wrapper>
   );
};

export default Footer;
